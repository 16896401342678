<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="NBnutbarCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import NBbarNutCashew from "../assets/NUTBERRY/NBnutbar/NB-nf-cashew.jpg";
import NBbarNutMacadamia from "../assets/NUTBERRY/NBnutbar/NB-nf-makadamia.jpg";
import NBbarNutPecan from "../assets/NUTBERRY/NBnutbar/NB-nf-pecan.jpg";
import NBbarNutHuzelnut from "../assets/NUTBERRY/NBnutbar/NB-nf-huzelnut.jpg";

export default {
  name: "NutberryNutBar",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Батончики на 100% из орехов, фруктов и семечек",
      NBnutbarCards: [
        {
          picture: NBbarNutCashew,
          text: "NUTBERRY Орехово-фруктовый батончик с кешью 35 г",
          id: 1,
          description:
            "Состав: финиковая паста (финик сушеный), абрикос сушеный, изюм (виноград сушеный без косточки, подсолнечное масло), сушеные ядра орехов кешью и фундука, ядра орехов миндаля жареные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 5,8 г, Жиры 6,5 г, Углеводы 48,8 г, пищевые волокна 13,2 г<br>Энергетическая ценность 303 Ккал.<br>Хранить при температуре от +5 °С до +25°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBbarNutMacadamia,
          text: "NUTBERRY Орехово-фруктовый батончик с макадамией 35 г",
          id: 2,
          description:
            "Состав: финиковая паста (финик сушеный), банан сушеный, ядра бразильского ореха сушеные, ядра ореха миндаля жареные, ядра ореха макадамии сушеные,  семена тыквы сушеные.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 6,2 г, Жиры 5,6 г, Углеводы 51,4 г, пищевые волокна 15,6 г<br>Энергетическая ценность 312 Ккал.<br>Хранить при температуре не выше +20°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBbarNutPecan,
          text: "NUTBERRY Орехово-фруктовый батончик с пеканом 35 г",
          id: 3,
          description:
            "Состав: финиковая паста (финик сушеный), абрикос сушеный, ядра арахиса жареные, инжир сушеный, сушеные ядра орехов фундука и пекана.<br>Продукт может содержать фрагменты скорлупы, следы кунжута и других орехов.<br>Пищевая ценность в 100 г: Белки 8,6 г, Жиры 7,8 г, Углеводы 47,6 г, пищевые волокна 13,9 г<br>Энергетическая ценность 323 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBbarNutHuzelnut,
          text: "NUTBERRY Орехово-фруктовый батончик с фундуком 35 г",
          id: 4,
          description:
            "Состав: финиковая паста (финик сушеный), ядра арахиса жареные, инжир сушеный, сушеные ядра орехов кешью и фундука,  семена кунжута.<br>Продукт может содержать фрагменты скорлупы и других орехов.<br>Пищевая ценность в 100 г: Белки 10,3 г, Жиры 13,4 г, Углеводы 42,4 г, пищевые волокна 14,3 г<br>Энергетическая ценность 360 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
